<template>
  <div class="uk-container uk-margin-medium-top">
    <h2>{{ video.discussion.title }}</h2>
    <div class="uk-grid">
      <div class="uk-width-3-4@m" v-if="! loading">
        <video width="100%" controls>
          <source :src="video.url" type="video/mp4">
        </video>
      </div>
      <div class="uk-width-1-4@m">
        Posted by
        <div class="uk-card uk-card-default uk-card-small uk-text-center">
          <router-link :to="{ name: 'trader.users.show', params: { id: video.user.id }}"><img class="uk-border-circle uk-margin-small-top" width="100" height="100" :src="video.user.avatar"></router-link>
          <div class="uk-card-body">
            <router-link :to="{ name: 'trader.users.show', params: { id: video.user.id }}">
              <h4 class="uk-margin-remove">{{ video.user.name }}</h4>
            </router-link>
            <p class="uk-text-muted">{{ video.date }}</p>
            <router-link :to="{ name: 'trader.users.show', params: { id: video.user.id }}" class="uk-button uk-button-primary">
              View Profile
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      video: { discussion: {}, user: {} },
      loading: true
    }
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.loading = true

      this.$axios.get(`/api/v1/trader/post-analysis/${this.$route.params.id}`).then(response => {
        this.video = response.data.video
        this.$loader.stop()
        this.loading = false
      })
    }
  }
}
</script>